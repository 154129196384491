@import "styles/variables";

.searchBlockContainer {
  background-color: $WHITE;
  box-shadow: 0 0.3rem 0.6rem 0 rgb(0 0 0 / 16%);
  position: fixed;
  right: 0;
  left: 0;
  z-index: 12;
  transition: top 500ms cubic-bezier(0.4, 0, 0.2, 1);

  @include tablet-landscape {
    background-color: $WHITE;
    box-shadow: 0 0 2rem 0 rgb(0 0 0 / 10%);
  }
}

.shownSearchBlock {
  top: 0;
  box-shadow: 0 0.4rem 0.6rem 0 rgb(30 50 93 / 20%);

  .innerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.8rem 0;

    .searchBlock {
      display: flex;
      justify-content: space-between;
      min-width: 0;
      flex-grow: 1;

      .date,
      .separator,
      .time {
        margin-right: 0.5rem;
      }

      .pickup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.3rem;
        margin-right: 1.2rem;
        padding: 0.8rem 0.4rem;
        color: $MARINE;
        letter-spacing: normal;
        min-width: 0;
        flex-grow: 1;
        border: solid 0.1rem $LIGHTGREY-BACKGROUND;
        border-radius: 0.6rem;
        cursor: pointer;

        @include tablet-landscape {
          padding: 0 0.4rem;
          border: none;
        }

        .dates {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1rem;
          line-height: 2.2rem;
          text-transform: capitalize;

          @include tablet-landscape {
            font-size: 1.5rem;
            line-height: 2.33rem;
          }

          .iconContainer {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
          }

          .dateContainer {
            display: flex;
            align-items: center;

            .separator {
              text-transform: lowercase;
            }
          }
        }

        .addressContainer {
          display: flex;
          width: 100%;
          font-size: 1.8rem;
          line-height: 1.94rem;
          min-width: 0;
          flex-grow: 1;
          white-space: nowrap;

          .address {
            margin-top: 0.3rem;
            margin-right: 0.5rem;
            font-weight: $FONT-WEIGHT-SEMI-BOLD;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include tablet-landscape {
              margin-bottom: 1rem;
            }
          }
        }
      }

      .modifyButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
        padding: 1rem 0.4rem;
        align-self: stretch;
        border: solid 0.1rem $LIGHTGREY-BACKGROUND;
        border-radius: 0.6rem;
        cursor: pointer;
        position: relative;

        @include tablet-landscape {
          padding: 1rem 1.05rem;
        }

        .label {
          color: $MARINE;
          font-size: 1.5rem;
          font-weight: $FONT-WEIGHT-SEMI-BOLD;
          line-height: 2.4rem;
        }
      }
    }
  }
}

.hiddenSearchBlock {
  top: -10rem;
}
