@import "styles/variables";

.label {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
}

.input {
  height: 6rem;
  padding-left: 5.2rem;
  color: $GUNMETAL;
  font-size: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0.1rem solid $LIGHT-PERIWINKLE;
  border-radius: 0.4rem;

  &:focus {
    border: 0.1rem solid $LIGHT-PERIWINKLE;
  }

  &:hover {
    outline: 0.1rem solid $OCEAN-BLUE;
    outline-offset: -0.1rem;
  }
}

.error {
  border: 0.1rem solid $ERROR-RED !important;

  &:hover {
    outline: 0.1rem solid $ERROR-RED;
    outline-offset: -0.1rem;
  }
}

.hovered {
  background-color: $VERY-LIGHT-BLUE;
}

.icon {
  padding: 0.8rem;
}

.dropdownHidden {
  display: none;
}

.dropdownShown ::after {
  display: none;
}

.thinBorderRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0.05rem;
}

.thinBorderLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0.05rem;
}
