@import "styles/variables";

.label {
  font-size: 1.3rem;
  font-weight: $FONT-WEIGHT-SEMI-BOLD;

  @include tablet-landscape {
    font-size: $FONT-SIZE-L;
  }
}

.checkedLabel {
  color: $OCEAN-BLUE;
}

.uncheckedLabel {
  color: $GUNMETAL;
}

.disabledLabel {
  color: $BLUEY-GREY;
}

.checkboxInput {
  cursor: pointer;
  border-color: $GUNMETAL;
}

.disabledCheckboxInput {
  cursor: not-allowed;
}
