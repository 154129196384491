@import "styles/variables";

.bannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.65rem;
  padding: 1.2rem 0 1.2rem 1.5rem;
  background-color: $MARINE;
  position: relative;
  border-radius: 0.4rem;

  @include tablet-landscape {
    margin-top: 2.55rem;
    padding: 0;
  }

  .promoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: $MARINE;
    border-radius: 0.4rem;

    .categoryContainer {
      width: 11.9rem;
      padding: 0.4rem 0;
      position: absolute;
      top: -1.8rem;
      left: 0;
      border-radius: 0.4rem;
      text-align: center;

      @include tablet-landscape {
        width: 14.4rem;
        padding: 0.7rem 0;
        position: static;
        top: 0;
      }
    }

    .goodDealContainer {
      background-color: $LIPSTICK;
    }

    .newContainer {
      background-color: $SEA;
    }

    .infoContainer {
      background-color: $GOLD;
    }

    .categoryLabel {
      color: $WHITE;
      font-size: 1.5rem;
      font-weight: $FONT-WEIGHT-BOLD;
      letter-spacing: 0.15rem;
    }

    .promoDescription {
      margin: 0.7rem 1rem 0.9rem 0;
      color: $WHITE;
      font-size: 1.3rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      text-align: left;

      @include tablet-landscape {
        margin: 0.8rem 1rem 0.8rem 2rem;
      }
    }

    .arrowContainer {
      display: flex;
      align-items: center;
    }
  }

  .crossButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.4rem;
    background-color: $MARINE;
  }
}
