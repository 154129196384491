@import "styles/variables";

.inputWrapperRoot {
  width: 100%;

  @include tablet-landscape {
    width: fit-content;
  }
}

.inputWrapperError {
  width: 100%;

  @include tablet-landscape {
    max-width: 24.8rem;
  }
}

.dateTimePickerContainer {
  display: flex;
  width: 100%;

  @include tablet-landscape {
    width: fit-content;
  }
}

.datePickerWrapper {
  width: 100%;

  @include tablet-landscape {
    width: 17.5rem;
  }
}

.datePickerInput {
  height: 6.2rem;
  padding-left: 4.8rem;
  font-size: 1.3rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0.05rem;
  text-transform: capitalize;
  border-color: #dfe1e4;

  &::placeholder {
    text-transform: initial;
  }

  &:hover,
  &:focus {
    outline: 0.1rem solid $OCEAN-BLUE;
    outline-offset: -0.1rem;
  }
}

.datePickerIcon {
  padding: 0.8rem;
}

.selectInput {
  width: 7.3rem;
  height: 6.2rem;
  padding: 1.5rem 1.2rem;
  color: $GUNMETAL;
  font-size: 1.3rem;
  text-align: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #dfe1e4;

  &:hover,
  &:focus {
    outline: 0.1rem solid $OCEAN-BLUE;
    outline-offset: -0.1rem;
  }
}

.selectHovered {
  background-color: $VERY-LIGHT-BLUE;
}

$SELECT-WIDTH: 7.3rem;
$MOBILE-DATEPICKER-WIDTH: 24.2rem;
$TABLET-PORTRAIT_DATEPICKER-WIDTH: 57.7rem;
$TABLET-LANDSCAPE-DATEPICKER-WIDTH: 17.5rem;

.selectDropdown {
  width: $MOBILE-DATEPICKER-WIDTH + $SELECT-WIDTH !important;
  max-height: 17.5rem !important;
  position: absolute !important;
  top: 0;
  left: -$MOBILE-DATEPICKER-WIDTH;

  @include tablet-portrait {
    width: $TABLET-PORTRAIT_DATEPICKER-WIDTH + $SELECT-WIDTH !important;
    left: -$TABLET-PORTRAIT_DATEPICKER-WIDTH !important;
  }

  @include tablet-landscape {
    width: $TABLET-LANDSCAPE-DATEPICKER-WIDTH + $SELECT-WIDTH !important;
    left: -$TABLET-LANDSCAPE-DATEPICKER-WIDTH !important;
  }
}

.dropdownItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectRightSection {
  display: none;
}

.dateTimePickerError {
  border: 0.1rem solid $ERROR-RED;

  &:hover,
  &:focus {
    outline: 0.1rem solid $ERROR-RED;
    outline-offset: -0.1rem;
  }
}

.datePickerEveryday {
  color: $GUNMETAL !important;
  border-radius: 0;

  &:not(.datePickerRangeStartTip, .datePickerRangeEndTip, .datePickerInRange) {
    &:hover {
      color: $OCEAN-BLUE !important;
      background-color: $VERY-LIGHT-BLUE;
      border-radius: 50%;
    }
  }

  &:focus {
    outline: none;
  }
}

.datePickerToday {
  border: 0.1rem solid $OCEAN-BLUE;
  border-radius: 50%;
}

.datePickerInRange {
  background-color: $EXTREMELY-LIGHT-BLUE;
}

.datePickerRangeStartTip,
.datePickerRangeEndTip {
  color: $OCEAN-BLUE !important;
  font-weight: $FONT-WEIGHT-SEMI-BOLD;
  background: $VERY-LIGHT-BLUE !important;
}

.datePickerRangeStartTip {
  border-radius: 50% 0 0 50%;
}

.datePickerRangeEndTip {
  border-radius: 0 50% 50% 0;
}

.datePickerRangeStartTip.datePickerRangeEndTip {
  border-radius: 50%;
}

.datePickerDisabledDay {
  opacity: 0;
}

.loading {
  cursor: wait;
}

.fullWidth {
  width: 100%;
}
