@import "styles/variables";

.overflowContainer {
  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    width: 100%;
    border: 0.1rem solid $LIGHT-PERIWINKLE;
    border-radius: 0.6rem;
    white-space: nowrap;

    @include tablet-portrait {
      width: fit-content;
    }
  }

  .buttonContainer {
    padding: 1rem 1.5rem;
    color: $MARINE;
    border-radius: 0.5rem;
    position: relative;
    flex-grow: 1;

    @include tablet-portrait {
      width: 18.8rem;
      height: 4.8rem;
      padding: 1rem $PADDING-XL;
      flex-grow: 0;
    }

    &.active {
      color: $WHITE;
      background-color: $OCEAN-BLUE;
    }

    .underline {
      width: 100%;
      height: 0.3rem;
      border-radius: 0.4rem;
      bottom: 0;
      left: 0;
      position: absolute;
    }

    &:hover:not(.active) > .underline {
      background-color: $OCEAN-BLUE;
    }

    .buttonContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include tablet-portrait {
        flex-direction: row;
      }

      .buttonText {
        margin-top: 0.35rem;
        font-size: 1.3rem;
        font-weight: $FONT-WEIGHT-SEMI-BOLD;

        @include tablet-portrait {
          margin-top: 0;
          margin-left: 1rem;
          font-size: 1.5rem;
          letter-spacing: 0.15rem;
        }
      }
    }
  }
}
