@import "styles/variables";

.hidden {
  opacity: 0;
}

.searchBlockContainer {
  padding: $PADDING-XL;
  background-color: $WHITE;
  border-radius: 0.6rem;
  box-shadow: 0 0 2rem 0 rgb(0 0 0 / 10%);

  @include tablet-landscape {
    padding: $PADDING-XXL;
  }

  .searchBlockContent {
    display: flex;
    flex-direction: column;
  }
}

.vehicleTypeContainer {
  margin-bottom: 1.5rem;

  @include tablet-landscape {
    font-size: 2.5rem;
  }
}

.checkboxContainer {
  margin: 1.5rem 0 2rem;
}

.container {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }
}

.roundTripContainer {
  width: 100%;
  margin-bottom: 1.5rem;

  @include desktop {
    width: 50%;
    margin-bottom: 0;
    margin-right: 3.25rem;
    max-width: 45.7rem;
  }

  .roundTripSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @include tablet-landscape {
      flex-direction: row;
      gap: 0;
    }

    & > div {
      width: 100%;
      flex-grow: 1;
    }
  }
}

.dateTimePickersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.5rem 0;

  @include tablet-landscape {
    flex-direction: row;
    align-items: flex-start;
    gap: 0 3.25rem;
  }

  @include desktop {
    justify-content: flex-end;
  }
}

.searchButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-grow: 1;

  @include tablet-landscape {
    width: fit-content;
    align-self: flex-end;
  }

  @include desktop {
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}
