@import "styles/variables";
@import "components/layout/headerLegacy/HeaderLegacy.module";

.heroBlockWithSearchBlock {
  /* stylelint-disable-next-line declaration-colon-newline-after */
  background: linear-gradient(
    to bottom,
    $OCEAN-BLUE 0%,
    $OCEAN-BLUE 65%,
    $WHITE 65%,
    $WHITE 100%
  );
}

.heroBlockWithoutSearchBlock {
  padding-bottom: 2.5rem;
  background: $OCEAN-BLUE;
}

.heroBlock {
  padding-top: 2.2rem;
  position: relative;

  .imageWrapper {
    display: none;
    width: 100%;
    height: 30rem;
    position: absolute;
    top: -$HEADER_TABLET_HEIGHT;
    left: 0;
    z-index: 0;

    @include tablet-landscape {
      display: block;
    }
  }

  .mobilePaddingContainer {
    padding: 0;
    position: relative;

    @include tablet-landscape {
      padding: 0;
    }
  }

  h1 {
    width: 100%;
    margin-bottom: 1.3rem;
    color: $WHITE !important;
    font-size: 2.5rem;
    font-weight: $FONT-WEIGHT-BOLD;
    z-index: 100;
    letter-spacing: 0.188rem;
    text-transform: uppercase;

    @include tablet-portrait {
      margin-bottom: 0.35rem;
      font-size: 2.5rem;
    }
  }

  h3 {
    margin-top: 1rem;
    color: white;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
  }

  .heroBlockUnderline {
    width: 3rem;
    height: 0.4rem;
    background-color: $CHERRY-RED;
    border-radius: 0.4rem;
  }

  .searchBlockPositioner {
    width: 100%;
    margin-top: 3rem;

    @include tablet-landscape {
      margin-top: 1.5rem;
      padding: 0;
    }
  }
}
